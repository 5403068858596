@tailwind base;
@tailwind components;
@tailwind utilities;
.center {
@apply align-middle justify-center items-center content-center;
}
.justified {
@apply justify-center;
}
.aligned {
@apply align-middle items-center content-center;
}


@font-face {
    font-family: Montserrat;
    src: url(../public/fonts/Montserrat-Regular.ttf);
}
@font-face {
    font-family: MontserratDemi;
    src: url(../public/fonts/Montserrat-Medium.ttf);
}
@font-face {
    font-family: MontserratExtraBold;
    src: url(../public/fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
    font-family: MontserratBold;
    src: url(../public/fonts/Montserrat-Bold.ttf);
}
